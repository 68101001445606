.introSectionWrapper {
  background: white;
  z-index: 9;
  position: relative;

  &::after {
    content: "";
    width: 101%;
    height: 100%;
    position: absolute;
    background: inherit;
    z-index: -1;
    bottom: 0;
    top: 0px;
    transform-origin: left bottom;
    transform: skewY(5deg);
  }
  &::before {
    content: "";
    width: 101%;
    height: 100%;
    position: absolute;
    background: inherit;
    z-index: -1;
    bottom: 0;
    top: 0px;
    transform: skewY(5deg);
    transform-origin: right bottom;
  }

  padding: 80px 0;
}

.sectionsWrapper {
  display: flex;
  flex-direction: row;
  width: 1332px;
  margin: 0 auto;
}

.rightSection {
  display: flex;
  flex-direction: column;
  padding-top: 85px;
}
.yellowPlanet {
  background-image: url("../Assets/imgs/yellow_planet.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 352px;
  height: 219px;
  margin-right: 35px;
}
.bluePlanet {
  background-image: url("../Assets/imgs/blue_planet.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 166px;
  height: 195px;
  margin-right: 35px;
  margin-left: auto;
  height: 100px;
}

.leftSection {
  width: 970px;
  header {
    text-align: left;
    font: Bold 100px/130px Oswald;
    letter-spacing: -2px;
    color: #080326;
    opacity: 1;
  }
  article {
    text-align: left;
    font: 23px/30px Heebo;
    letter-spacing: 0;
    color: #080326;
    opacity: 1;
  }
}
