.sectionWrapper {
  position: relative;
  height: 4580px;
  z-index: 7;
}
.sectionBackground {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}

.rocket {
  background-image: url("../Assets/imgs/CheckOutSection/rocket.png");
  background-size: cover;
  background-position: center;
  width: 565px;
  height: 911px;
  position: absolute;
  top: 1870px;
  overflow: hidden;
  left: 60%;
}

.top {
  height: 50%;
  background-color: #16ffbd;
  z-index: 10;
  position: relative;
  &::after {
    content: "";
    width: 101%;
    height: 100%;
    position: absolute;
    background: inherit;
    z-index: -1;
    bottom: 0;
    top: 0px;
    transform-origin: left bottom;
    transform: skewY(5deg);
  }
}

.bottom {
  z-index: 9;
  height: 50%;
  background-color: #edab06;
  position: relative;
  &::after {
    content: "";
    width: 101%;
    height: 100%;
    position: absolute;
    background: inherit;
    z-index: -1;
    bottom: 0;
    top: 3%;
    transform-origin: left bottom;
    transform: skewY(5deg);
  }
}

.topTitle {
  text-align: center;
  font: Bold 100px/130px Oswald;
  letter-spacing: -2px;
  color: #080326;
  opacity: 1;
  padding-top: 260px;
}

.cardGrid {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0 auto;
  padding-top: 118px;
  width: 1332px;
}

.cardWrapper {
  flex-basis: 420px;
  margin: 10px;
}
