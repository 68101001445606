.sectionWrapper {
  background: #edab06;
  // height: 400px;
  width: 100%;
  z-index: 8;
  position: relative;
  padding-top: 240px;
  &::after {
    content: "";
    width: 101%;
    height: 100%;
    position: absolute;
    background: inherit;
    z-index: -1;
    bottom: 0;
    top: 0px;
    transform-origin: left bottom;
    transform: skewY(5deg);
  }
}

.sectionContainer {
  width: 1322px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 125px;
  margin: 0 auto;
}

.title {
  padding-bottom: 125px;
  text-align: center;
  font: Bold 100px/130px Oswald;
  letter-spacing: -2px;
  color: #080326;
  opacity: 1;
}

.cardGrid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  width: 1332px;
}

.cardWrapper {
  flex-basis: 420px;
  margin: 10px;
}
