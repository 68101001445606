.textcardwrapper {
  padding: 120px 10px;
  height: calc(100% - 240px);
  width: calc(100% - 20px);
  cursor: pointer;

  .title {
    text-align: center;
    font: 27px/65px Heebo;
    letter-spacing: 0;
  }
  .subTitle {
    text-align: center;
    font: Bold 58px/65px Heebo;
    letter-spacing: 0;
  }
}
