.dialogContainer {
  position: relative;
  width: 100%;
  height: 100%;
}
.closeIcon {
  width: 23px;
  height: 23px;
  margin-right: 28px;
  margin-top: 28px;
  margin-bottom: 17px;
  cursor: pointer;
}
.dialogTop {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.dialogContent {
  display: flex;
  width: calc(100% - 104px);
  height: calc(100% - 129px);
  padding: 0 52px 52px 52px;
}
