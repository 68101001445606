.carouselContainer {
  display: flex;
  flex-direction: row;
}

.left {
  width: 445px;
  padding-right: 89px;
  display: flex;
  flex-direction: column;
}

.img {
  width: 670px;
  height: 670px;
  padding-top: 22px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top;
}

.subTitle {
  text-align: left;
  font: 30px/44px Heebo;
  letter-spacing: 0;
  color: #080326;
  opacity: 1;
}

.title {
  text-align: left;
  font: Bold 57px/70px Heebo;
  letter-spacing: 0;
  color: #080326;
  opacity: 1;
}

.description {
  text-align: left;
  font: 23px/32px Heebo;
  letter-spacing: 0;
  color: #080326;
  opacity: 1;
}

.controls {
  margin-top: auto;
  display: flex;
  justify-content: center;
  svg {
    width: 17px;
    cursor: pointer;
    // path {
    //   fill: rgba(8, 3, 38, 1);
    // }
    &.last,
    &.first {
      path {
        fill: gray;
      }
      cursor: auto;
    }
  }
}

.leftChevron {
  transform: rotate(180deg);
  margin-right: 40px;
}

.rightChevron {
  margin-left: 40px;
}
