.content {
  height: calc(100% - 324px);

  width: calc(100% - 40px);
  background-color: #080326;
  padding: 11px 20px;
  text-align: center;
}

.img {
  height: 302px;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.mediaCardwrapper {
  height: 100%;
  width: 100%;
  cursor: pointer;
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  &.fullImg {
    height: 100%;
    align-items: stretch;
    .img {
      height: auto;
      width: auto;
      margin: 50px;
      display: flex;
      flex: auto;
      background-size: contain;
    }
  }
}

.title {
  text-align: left;
  font: 24px/53px Heebo;
  letter-spacing: 0;
  color: #ffffff;
}

.subTitle {
  text-align: left;
  font: Bold 48px/53px Heebo;
  letter-spacing: 0;
  color: #ffffff;
}
