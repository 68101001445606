.topSection {
  width: 100%;
  height: 900px;
  // background-image: url("../Assets//imgs/top_section_bg.png");
  background-image: url("https://firebasestorage.googleapis.com/v0/b/roman-feigin-web-78e9c.appspot.com/o/top_section_bg.png?alt=media&token=2797e7ad-e459-40ca-9e30-5809f388110c");
  background-position: center -700px;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 10;
  &::after {
    content: "";
    background-image: url("../Assets//imgs/shadow.png");
    background-position: center;
    background-size: cover;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0.35;
  }
}

.topSectionWrpper {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.navBar {
  padding-top: 52px;
  display: flex;
  flex-direction: row;
  width: 1322px;
  z-index: 1;
}

.left {
  white-space: nowrap;
  text-align: left;
  font-family: Heebo;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  color: white;
  text-transform: uppercase;
}

.right {
  margin-left: auto;
  text-align: right;
  font: 24px/35px Heebo;
  letter-spacing: 0;
  color: white;
  text-shadow: 0px 3px 6px #00000029;
  opacity: 1;
}
.mainTitleWrapper {
  text-align: left;
  color: #ffffff;
  text-shadow: 0px 6px 6px #00000059;
  opacity: 1;
  margin: auto;
  z-index: 1;
}
.mainTitle {
  text-align: left;
  font: Bold 271px/318px Raleway;
  letter-spacing: 0;
  color: white;
}

.iLetter {
  text-align: left;
  font: 271px/318px Lobster;
  letter-spacing: 13.55px;
  color: white;
}

.letsTalk {
  margin-left: 60px;
  cursor: pointer;
}

.downloadSVG {
  width: 24px;
  height: 20px;
}

.arrowDown {
  width: 42px;
  height: 40px;
  margin: 0 auto;
  padding-bottom: 100px;
  z-index: 1;
}
.infoContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.intro {
  text-align: center;
  font: 24px/35px Heebo;
  letter-spacing: 0;
  color: #080326;
  opacity: 1;
}

.phone {
  text-align: center;
  font: Bold 100px/130px Heebo;
  letter-spacing: 0;
  color: #080326;
  opacity: 1;
}

.email {
  text-align: center;
  font: 24px/112px Heebo;
  letter-spacing: 0;
  color: #080326;
  opacity: 1;
}

.link {
  color: inherit;
  text-decoration: inherit;
}
