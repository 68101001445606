.sectionWrapper {
  background-color: white;
  z-index: 10;
  margin-top: 174px;
  position: relative;
  &::before {
    content: "";
    width: 101%;
    height: 100%;
    position: absolute;
    background: inherit;
    z-index: -1;
    bottom: 0;
    top: 0px;
    transform: skewY(5deg);
    transform-origin: right bottom;
  }
}

.cardGrid {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-top: 118px;
  padding-bottom: 128px;
  width: 1332px;
  margin: 0 auto;
}

.cardWrapper {
  flex-basis: 420px;
  margin: 10px;
}

.note {
  text-align: left;
  font: 22px/32px Oswald;
  letter-spacing: 0;
  color: #080326;
  opacity: 1;
  padding-bottom: 60px;
  margin: 0 auto;
  width: 1332px;
}
